<template>
    <div>
        <v-treeview
            activatable
            :items="arbolOrdenado"
            color="info"
            open-all
            open-on-click
        />
    </div>
</template>
<script>
import { isNil } from 'lodash';

export default {
    name: 'ArbolObs',
    props: {
        arbol: { type: Array, required: true },
    },
    computed: {
        arbolOrdenado() {
            const padre = this.arbol.find((obs) => isNil(obs.id_obs_padre));

            return !padre ? [] : [this.build(padre.id, this.arbol)];
        },
    },
    methods: {
         build(id, elementos, parent) {
            const obs = elementos.find((ob) => ob.id === id);
            const children = elementos.filter((ob) => ob.id_obs_padre === id);
            const obsInTree = {
                id: obs.id,
                name: `${obs.codigo} - ${obs.nombre}`,
                children: [],
                style: "margin-left: 0 !important",
                class: "ml-0"
            };

            children.forEach((child) => this.build(child.id, elementos, obsInTree));

            if (parent) parent.children.push(obsInTree);

            return obsInTree;
        }
    },
}
</script>
<style scoped>
:deep(.v-treeview-node__root) {
    padding-left: 0 !important;
}

:deep(.v-treeview-node__level) {
    width: 0 !important;
}

:deep(.v-treeview-node.v-treeview-node--leaf) {
    padding-left: 24px !important;
}

:deep(.v-treeview-node__root) {
    padding-left: 8px !important;
}

:deep(.v-treeview-node__label) {
    overflow: auto !important;
    word-break: break-word !important;
    white-space: break-spaces !important;
    display: block;
    word-wrap: break-word;
}
</style>