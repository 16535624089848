<template>
        <v-container>
            <NavButton class="mb-4" />
            <RequestStatusFeedbackComponent />
            <SpinnerComponent :spinning="product.isLoading" text="Cargando producto..." />
            <div v-if="product.data" class="product-details mb-15">
                <v-img
                    @click.stop="modalVerImagen = true"
                    max-height="200px"
                    max-width="200px"
                    :src="product.data.foto"
                    class="product-image"
                    style="cursor: pointer;"
                    contain
                >
                    <v-icon
                        v-if="!product.data.foto"
                        style="font-size: 200px; display: flex; justify-content: center; align-items: center;"
                    >
                        mdi mdi-image
                    </v-icon>
                </v-img>
                <div class="product-meta">
                    <h3 class="display-1">{{ product.data.nombre }}</h3>
                    <v-divider class="my-1"></v-divider>
                    <p class="body-1">{{  product.data.descripcion }}</p>
                    <p class="mt-2 body-2">
                        <v-icon class="mr-1">mdi-clipboard-search</v-icon>
                        <b>SKU: </b>
                        {{ product.data.cmsku.sku ?? '0' }}
                    </p>
                    <p class="mt-2 body-2">
                        <v-icon class="mr-1">mdi-store</v-icon>
                        <b>Disponibilidad: </b>
                        {{ product.data.disponibilidad ?? '0' }}
                    </p>
                    <p class="mt-2 body-2">
                        <v-icon class="mr-1">mdi-star-box</v-icon>
                        <b>OBS: </b>
                        <ArbolObs 
                            class="mt-4" 
                            :arbol="product.data.arbol_obs"
                        />
                    </p>
                    <p class="mt-2 body-2" v-if="!noHayAtributos">
                        <span class="font-weight-bold">Atributos:</span>
                        <v-chip v-for="atributoSubGrupo in product.data.cmsgp" :key="atributoSubGrupo.id" small class="ma-2" style="flex: 0 1 auto;">
                            {{ atributoSubGrupo?.CMAtributoSubgrupo.CMAtributo?.nombre }}
                        </v-chip>
                    </p>
                </div>
            </div>
            <OfferListComponent 
                :productKind="productKind"
                :productId="productId" 
                :timer="timer"
                :arbol-obs="arbolObs"
            />
            <ModalImagenProducto 
                :is-open="modalVerImagen"
                :src="product.data?.foto"
                @on-visibility-change="manejarVisibilidadModalVerImagen"
            />
        </v-container>
</template>
<script> 
import { OfferListComponent } from '@/components/catalogoEnLinea/purchaseFlow'
import { RequestStatusFeedbackComponent } from '@/components/catalogoEnLinea/purchaseFlow';
import { SpinnerComponent, NavButton } from '@/components/utils';
import ModalImagenProducto from './ModalImagenProducto.vue';
import { mapState, mapActions, mapMutations } from 'vuex';
import { Fragment } from 'vue-fragment';
import ArbolObs from './ArbolObs.vue';

export default {
    name: 'OfertasProductoView',
    components: {
    SpinnerComponent,
    OfferListComponent,
    Fragment,
    NavButton,
    RequestStatusFeedbackComponent,
    ArbolObs,
    ModalImagenProducto,
},
    computed: {
        ...mapState(["userInfo"]),
        ...mapState("catalogoEnLinea", ["timer", "product", "productFunds"]),
        productKind() {
            return this.product.data?.id_tipo_producto;
        },
        noHayAtributos(){
            return this.product.data.cmsgp.length === 0
        },
        arbolObs(){
            return this.product.data?.arbol_obs ?? [];
        },
    },
    data: () => ({
        productId: null,
        modalVerImagen: false,
    }),
    methods: {
        ...mapActions("catalogoEnLinea", ["fetchProduct"]),
        ...mapMutations("catalogoEnLinea", ["resetProductData"]),
        manejarVisibilidadModalVerImagen(visible) {
            this.modalVerImagen = visible;
        },
    },
    created() {
        const id = this.$route.params.productId;
        this.productId = Number(id);
        this.fetchProduct(id);
    },
    beforeDestroy() {
        this.resetProductData();
    },
}
</script>
<style scoped>
    :deep(.product-details) {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        gap: 16px;
    }

    :deep(.product-image) {
        flex: 1 1 200px;
    }

    :deep(.product-meta) {
        flex: 1 1 320px;
        max-width: 100%;
    }

    :deep(.v-image__image) {
        background-position: top center !important;
    }
</style>
