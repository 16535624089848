<template>
    <v-dialog v-model="internalOpen" max-width="800px">
        <div style="background-color: #FFFFFF; padding: 0 1em 1em; position: relative; z-index: 10000">
            <div 
                class="py-4 d-flex align-center" 
                style="width: 100%; background-color: inherit; position: sticky; top: 0; z-index: 10;"
            >
                <v-btn 
                    style="min-width: unset !important; min-height: unset !important; width: 32px !important; height: 32px !important;" 
                    class="px-4 py-4"
                    rounded
                    text
                    @click.stop="cerrarModal"
                >
                    <v-icon>mdi-window-close</v-icon>
                </v-btn>
            </div>

            <div class="px-1">
                <EmptyComponent :empty="!src" text="No hay nada por mostrar">
                    <v-img 
                        max-width="100%" 
                        :src="src" 
                        class="d-block mx-auto" 
                    />
                    <v-btn 
                        @click.stop="cerrarModal" 
                        class="d-block mx-auto mt-4" 
                        color="primary"
                    >
                        Cerrar
                    </v-btn>
                </EmptyComponent>
            </div>
        </div>
    </v-dialog>
</template>
<script>
import EmptyComponent from '@/components/utils/EmptyComponent.vue';
import { isNil } from 'lodash';

export default {
    name: 'ModalImagenProducto',
    emits: ['on-visibility-change'],
    components: { EmptyComponent },
    props: {
        isOpen: { type: Boolean },
        src: { type: String },
    },
    data: () => ({
        internalOpen: false,
    }),
    methods: {
        cerrarModal() {
            this.internalOpen = false;
        },
    },
    watch: {
        isOpen(value) {
            if (isNil(value)) return;

            this.internalOpen = value;
        },
        internalOpen(value) {
            this.$emit('on-visibility-change', value);
        },
    },
}
</script>